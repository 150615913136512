import { datadogLogs } from "@datadog/browser-logs";
import getEnv from "utils/getEnv";
import isPreviewMode from "utils/isPreviewMode";

datadogLogs.init({
  clientToken: getEnv("datadog_client_token", ""),
  site: "datadoghq.com",
  forwardErrorsToLogs: true,
  sampleRate: getEnv("datadog_sample_rate", 100),
  useSecureSessionCookie: true,
});

export default class Log {
  static loggingInActive() {
    return (process?.env?.NODE_ENV === "development") || isPreviewMode();
  }

  static debug(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.debug(message, messageContext);
  }

  static info(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.info(message, messageContext);
  }

  static warn(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.warn(message, messageContext);
  }

  static error(message, messageContext) {
    if (Log.loggingInActive()) return;

    datadogLogs.logger.error(message, messageContext);
  }
}

import getEnv from "utils/getEnv";
import Analytics from "check_in/analytics";
import DataHelper from "extension/utils/data_helper";

const ROOT_URL = getEnv("rootUrl");

export const eventNames = {
  AGREEMENT_PRACTICE_TERMS_OF_USE_REVIEWED: "Practice Terms of Use Reviewed",
  CARD_DELETED: "Card Deleted",
  CARD_READER_CONNECTION_FAILURE: "Card reader connection failed",
  CARD_READER_CONNECTION_SUCCESS: "Card reader successfully connected",
  CARD_VIEWED: "Card Viewed",
  CASH_PAYMENT_ATTEMPT: "Cash payment attempt",
  CLINICAL_INTEGRATED_PHARMACY_PATIENT_CONSENTS: "Clinical Integrated Pharmacy patient consents",
  COLLECTIONS_BALANCE_EXPLANATION_REVIEWED: "Reviewed Collections Balance Explanation",
  CREDIT_CARD_PAYMENT_ATTEMPT: "Credit card payment attempt",
  DRIVER_LICENSE_SCAN_FAILED: "Driver License scan failed",
  DRIVER_LICENSE_SCAN_SKIPPED: "Driver License scan skipped",
  DRIVER_LICENSE_SCAN_SUCCESSFUL: "Driver License scan successful",
  EPION_AUTHORIZATION_OPENED: "Epion Authorization Opened",
  EPION_AUTHORIZATION_PRESENTED: "Epion Authorization Presented",
  EPION_AUTHORIZATION_SIGNED: "Epion Authorization Signed",
  EPION_AUTHORIZATION_SKIPPED: "Epion Authorization Skipped",
  EPS_OFFER_IFRAME_MODAL_OPENED: "EPS Offer Iframe Modal Opened",
  EPS_PRACTICE_PROMO_COMPLETED: "EPS Practice Promo Completed",
  EPS_PRACTICE_PROMO_CTA_CLICKED: "EPS Practice Promo CTA Clicked",
  EPS_PRACTICE_PROMO_PRESENTED: "EPS Practice Promo Presented",
  EXTENSION_ARRIVAL_MANAGEMENT_STATUS_VIEWED: "Extension Arrival Management Status Viewed",
  EXTENSION_PATIENT_REGISTRATION_VIEWED: "Extension Patient Registration Viewed",
  EXTENSION_PREVISIT_HISTORY_VIEWED: "Extension Previsit History Viewed",
  EXTENSION_TEXT_MESSAGE_HISTORY_VIEWED: "Extension Text Message History Viewed",
  FORM_RENDER_SUBMIT: "Form builder html is saved",
  KIOSK_AUTOLOGOUT_CANCELLED: "Kiosk autologout cancelled",
  KIOSK_AUTOLOGOUT_TRIGGERED: "Kiosk autologout triggered",
  KIOSK_IPAD_AUTHENTICATION_ATTEMPT: "Kiosk iPad authentication attempt",
  KIOSK_MOBILE_AUTHENTICATION_ATTEMPT: "Kiosk mobile authentication attempt",
  KIOSK_MOBILE_MANUAL_AUTHENTICATION_VISIT: "Kiosk Patient visits manual authentication on mobile",
  KIOSK_SUMMIT_AUTHENTICATION_ATTEMPT: "Kiosk Summit My Health app authentication attempt",
  NOTICE_OF_PRIVACY_REVIEWED: "Notice of Privacy Reviewed",
  OFFLINE_SUBMIT_ATTEMPT: "Form timeout; re-submitting",
  OFFLINE_SUBMIT_EXCEPTION_MESSAGE: "Form timeout; re-submission failed",
  OPEN_CAMERA: "Patient Photo Camera Opened",
  PATIENT_AGREED_WITH_TERMS: "Payment Contract Patient Agreed with Terms of Use",
  PATIENT_AGREEMENT_REVIEWED: "Patient Agreement Reviewed",
  PAYMENT_CONTRACTS_E_COMMERCE_SCREEN_DISPLAYED: "Payment Contracts ecommerce screen displayed",
  PAYMENT_CONTRACTS_RETAIL_SCREEN_DISPLAYED: "Payment Contracts retail screen displayed",
  PAYMENT_CONTRACTS_SWIPE_DETECTED: "Payment Contracts credit card swipe detected",
  PAYMENT_CONTRACTS_SWIPE_NOT_DETECTED: "Payment Contracts credit card swipe not detected",
  PAYMENT_PLANS_REVIEWED: "Payment Plans Reviewed",
  PHARMACY_SEARCH_FORM_VALIDATION_ERRORS_ON_SUBMISSION: "Pharmacy search form validation errors on submission",
  PORTAL_INFORMATION_VIEWED: "Portal Information Viewed",
  PRIMARY_INSURANCE_DOES_NOT_NEED_UPDATING: "Primary Insurance Does Not Need Updating",
  PRIMARY_INSURANCE_NEEDS_UPDATING: "Primary Insurance Needs Updating",
  PV_SWIPE_DETECTED: "PV Payments Credit Card Swipe detected",
  PV_SWIPE_NOT_DETECTED: "PV Payments Credit Card Swipe Not Detected",
  REVIEWED_BALANCE_DETAILS: "Reviewed Balance Details",
  SWIPE_DETECTED: "PaymentPlus Credit Card swipe detected",
  SWIPE_NOT_DETECTED: "Swipe Not Detected",
  TERMS_REVIEWED: "Payment Contract Terms Reviewed",
  TRY_DETECT_SWIPE_AGAIN: "Swipe Not Detected: Try Again Button Tapped",
  UNIVERSAL_PAYMENTS_PATIENT_PAID_OUTSIDE_OF_WORKFLOW: "Universal Payments patient chose to pay outside of workflow",
  USED_DIFFERENT_ADDRESS: "Payment Contract Used a Different Address",
  USED_DIFFERENT_CARD: "Payment Contract Used a Different Card",
};

const checkEventNamePresence = (eventName) => {
  if (!Object.values(eventNames).includes(eventName)) {
    throw new Error(
      "The Data Team needs to be aware of the event you are trying to track. For that to happen, " +
      "please add it to the eventNames object in app/assets/src/utils/EventsTracking.js",
    );
  }
};

export const trackEvent = (eventName, eventAttributes) => {
  checkEventNamePresence(eventName);

  if (window.jsAnalytics) {
    return window.jsAnalytics.track(eventName, eventAttributes);
  }

  return Promise.resolve();
};

export const extensionPage = (path, properties) => {
  const uri = `insurance_extension${path}`.replace(/\/$/, "");
  const fullPath = `/${uri}`;
  const pageProperties = properties || {};

  pageProperties.url = ROOT_URL + uri;
  pageProperties.path = fullPath;
  pageProperties.title = fullPath;

  const eventProperties = {
    userId: DataHelper.epionUserProperties.username,
    ...pageProperties,
  };

  new Analytics(ROOT_URL).page(fullPath, eventProperties);
};

export const extensionTrack = (eventName, properties) => {
  checkEventNamePresence(eventName);

  const eventProperties = {
    userId: DataHelper.epionUserProperties.username,
    ...DataHelper.epionUserProperties,
    ...properties,
  };

  new Analytics(ROOT_URL).track(eventName, eventProperties);
};

export default { eventNames, extensionPage, extensionTrack, trackEvent };

import EventEmitter from "utils/EventEmitter";
import getEnv from "utils/getEnv"
import Log from "utils/logging";

export const MAINTENANCE_ERROR_EVENT = "MAINTENANCE_ERROR";
const SERVICE_UNAVAILABLE_STATUS = 503;

const requestToServer = async ({ path, method = "GET", body }) => {
  const pathWithParams = new URL(path);
  pathWithParams.searchParams.append("git_ref", getEnv("git_ref"));

  const response = await fetch(pathWithParams, {
    body: JSON.stringify(body),
    credentials: "include",
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      ...(method.toUpperCase() !== "GET" && { "Content-Type": "application/json" }),
    },
    method,
  });

  if (response.status === SERVICE_UNAVAILABLE_STATUS) {
    EventEmitter.dispatch(MAINTENANCE_ERROR_EVENT);
  }

  // Handling backend validation errors
  try {
    const data = await response.clone().json();
    if (data.errors?.length) {
      return Promise.reject(data.errors);
    }
  } catch (e) {
    Log.error("Error while parsing JSON response from server.");
  }

  if (response.status >= 500) {
    const errors = ["Something went wrong."];
    return Promise.reject(errors);
  }

  return response;
};

export default requestToServer;
